import { useEffect, useState, useRef } from "react";
// import { baseUrl } from "api/constants";
import dynamic from "next/dynamic";
// import { Col } from "react-bootstrap";
// import Image from "next/image";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { saveShortList } from "redux/actions/shortsAction";
// import AuthorColumn from "./../homePage/authorColumn";

import { useWindowSize } from "api/commonConstants";
const NewsTopbar = dynamic(() =>
  import("../../components/newsCard/newsTopbar")
);
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";

// import "swiper/modules/navigation/navigation.scss"; // Navigation module
// import "swiper/modules/free-mode/free-mode.scss"; // Pagination module

const StoryCard = ({
  storyData,
  storyShowData,
  setLoginPrompt,
  postType = "story",
}) => {
  const router = useRouter();
  return (
    <div className="">
      <div
        className=""
        // onClick={() => {
        //   router.push(`/short-video/${storyData?.postSlug}`);
        //   //   handleClick(story);
        //   //   handleView(story?._id);
        // }}
      >
        <a href={`/short-video/${storyData?.postSlug}`} passHref>
          {" "}
          {storyData?.headingImageUrl && (
            <img
              src={storyData?.headingImageUrl + "?width=190"}
              alt="Story image"
              className="short-slider-image-customstyle"
              loading="lazy"
              style={{ borderRadius: "4px" }}
              height={268}
            />
          )}
        </a>
      </div>
    </div>
  );
};
const ShortsSlider = ({
  shortsData = [],
  atOlympicsPage = false,
  authorWidgetData = [],
}) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [shortData, setShortData] = useState(shortsData);
  const size = useWindowSize();
  const dispatch = useDispatch();
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    dispatch(saveShortList(shortData));
  }, [shortData]);

  return (
    <>
      <div
        className="outer-main-conainer"
        style={{
          marginTop: "1.5rem",
          marginBottom: "1.5rem",
          borderRadius: "12px",
        }}
      >
        <div
          className={`${
            atOlympicsPage
              ? "news-card-body-olympic"
              : "main-containerTopShow show-widget-alignment"
          }`}
        >
          <div
            className="home-left-align-short"
            style={{
              width:
                authorWidgetData &&
                authorWidgetData.length > 0 &&
                size.width > 1024
                  ? "67%"
                  : "100%",
            }}
          >
            <div className="topShowsTopBar">
              <NewsTopbar
                videoEn={"Shorts"}
                videoHn={"शॉर्ट्स"}
                newsIcon={"shorts"}
                newsBgclassName={"videoShow"}
                newsBgClass={"videoShowTopBar"}
                videoTitleClass={"videoTitle"}
                videoImgClass={"videoImg"}
                videoLinkClass={"videoLink"}
              />
            </div>
            {
              <div className="checkMobileOrNot">
                <div className="scrollbarX" style={{ minHeight: "30.7rem" }}>
                  {shortData?.map((data, index) => (
                    <div key={`index_${index}_${data?._id}`}>
                      <div
                        className="stories-items"
                        style={{
                          width: "18.9rem",
                          height: "34.7rem",
                          marginRight: "1rem",
                        }}
                      >
                        <StoryCard storyData={data} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            }

            <div className="checkDesktopOrNot">
              <>
                <Swiper
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    400: {
                      slidesPerView: 3.5,
                    },
                    639: {
                      slidesPerView: 3.7,
                    },
                    865: {
                      slidesPerView: 3.9,
                    },
                    1000: {
                      slidesPerView: 4.2,
                    },
                    1025: {
                      slidesPerView: authorWidgetData?.length > 0 ? 4 : 6,
                    },
                    1350: {
                      slidesPerView: authorWidgetData?.length > 0 ? 4.5 : 6,
                    },
                    1500: {
                      slidesPerView: authorWidgetData?.length > 0 ? 5 : 7,
                    },
                    1700: {
                      slidesPerView: authorWidgetData?.length > 0 ? 5.4 : 7,
                    },
                  }}
                  spaceBetween={16}
                  freeMode={true}
                  // navigation={true}
                  pagination={{
                    clickable: true,
                  }}
                  onInit={(swiper) => {
                    swiper.params.navigation.prevEl = prevRef.current;
                    swiper.params.navigation.nextEl = nextRef.current;
                    console.log(prevRef.current);
                    console.log(nextRef.current);
                    swiper.on("slideChange", () => {
                      setIsBeginning(swiper.isBeginning);
                      setIsEnd(swiper.isEnd);
                    });
                    swiper.navigation.init();
                    swiper.navigation.update();
                  }}
                  modules={[FreeMode, Navigation]}
                  className="mySwiper shorts-slider"
                >
                  {shortData?.map((data, index) => (
                    <SwiperSlide key={index} index={index}>
                      <div index={index}>
                        <a
                          href={`/short-video/${data?.postSlug}`}
                          index={index}
                        >
                          <img
                            className="short-slider-img"
                            src={data?.headingImageUrl}
                          />
                        </a>
                      </div>
                    </SwiperSlide>
                  ))}
                  {/* <button onClick={() => swiper?.slidePrev()}>previous</button> */}
                  {
                    <div
                      className="shorts-slider-button-bwd"
                      style={
                        !isBeginning
                          ? { display: "block" }
                          : { display: "none" }
                      }
                      ref={prevRef}
                    >
                      <span>{"<"}</span>
                    </div>
                  }
                  {
                    <div
                      className="shorts-slider-button-fwd"
                      style={
                        !isEnd ? { display: "block" } : { display: "none" }
                      }
                      ref={nextRef}
                    >
                      <span>{">"}</span>
                    </div>
                  }
                </Swiper>
              </>
            </div>
          </div>

          {size.width <= 499 && !atOlympicsPage && (
            <div className="gray-home-seperator"></div>
          )}

          {/* {authorWidgetData && (
            <div className="home-right-align-author">
              {typeof size?.width !== "undefined" && (
                <AuthorColumn authorWidgetData={authorWidgetData} size={size} />
              )}
            </div>
          )} */}
          {size.width >= 499 && (
            <div
              className="container-row py-2"
              align="center"
              style={{
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: "12px",
              }}
            >
              <p
                style={{
                  fontSize: "11px",
                  color: "#b7b7b7",
                  marginBottom: "0px",
                }}
              >
                Advertisement
              </p>
              <div
                id={"div-gpt-ad-1701945220410-3"}
                style={{
                  minWidth: "300px",
                  minHeight: "100px",
                  display: "block !important",
                  alignContent: "center",
                  textAlign: "-webkit-center",
                }}
              >
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html: `
          {googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701945220410-3')})`,
                  }}
                ></script>
              </div>
            </div>
          )}
          {size.width < 499 && (
            <div className="container-row py-2" align="center">
              <p
                style={{
                  fontSize: "11px",
                  color: "#b7b7b7",
                  marginBottom: "0px",
                }}
              >
                Advertisement
              </p>
              <div
                id={"div-gpt-ad-1701946028239-3"}
                style={{
                  minWidth: "300px",
                  minHeight: "100px",
                  display: "block !important",
                  alignContent: "center",
                  textAlign: "-webkit-center",
                }}
              >
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html: `
          {googletag.cmd.push(function() { googletag.display('div-gpt-ad-1701946028239-3')})`,
                  }}
                ></script>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default ShortsSlider;
